import React, { useMemo } from "react";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { couponUrl } from "utils/coupon";
import { useAdminAuthentication } from "hooks/auth";
import {
  useClientDoc,
  useEnqueteDoc,
  useShopDoc,
  useSurveyDoc,
} from "models/hook";

import { CONSUMER } from "..";
import ConsumerLayout from "components/ConsumerLayout";
import { resolveRoute, useTypedNavigate } from "pages";
import { useFormatter, useStringIntl } from "hooks/intl";
import { useContextLocale } from "components/LocaleProvider";
import { ConsumerLocaleSelector } from "hooks/localeSelector";
import { checkTimeRange } from "utils/time";
import getSchema from "./schema";
import { PropertyType } from "schemaComponents";
import { shopData } from "common/models/shop";
import { useMemoWithPrev } from "hooks/common";
import { surveyData } from "common/models/survey";
import { getLogParams, trackingLog, useTrackingLog } from "utils/log";
import {
  MaintenaceMessage,
  isMaintenanceMode,
} from "components/MaintenanceMessage";
// eslint-disable-next-line camelcase
import { requestLottery_v2 } from "functions";
import { v2Mode } from "utils/v2Migration";
import { InjectedHTML } from "components/InjectedHTML";
import { useDocumentBrowser } from "hooks/dialogs";
import { termsOfServiceText } from "./termsOfService";
import { Navigate } from "react-router-dom";

export const CONSUMER_ENQUETE = CONSUMER.child("/enquete", ConsumerEnquete);
export const CONSUMER_PUBLIC_ENQUETE = CONSUMER.child(
  "/publicEnquete",
  ConsumerPublicEnquete
);

function ConsumerEnquete({
  shopId,
  shopData,
}: {
  shopId?: string;
  shopData?: shopData | null;
}) {
  return (
    <ConsumerLayout shopId={shopId} shopData={shopData}>
      <ConsumerEnqueteInternal shopId={shopId} shopData={shopData} />
    </ConsumerLayout>
  );
}

function ConsumerPublicEnquete({
  shopId,
  shopData,
}: {
  shopId?: string;
  shopData?: shopData | null;
}) {
  return (
    <ConsumerLayout
      shopId={shopId}
      shopData={shopData}
      skipAuthentication={true}
    >
      <ConsumerEnqueteInternal
        shopId={shopId}
        shopData={shopData}
        isPublic={true}
      />
    </ConsumerLayout>
  );
}

function ConsumerEnqueteInternal({
  shopId,
  shopData: injectedShopData,
  isPublic,
}: {
  shopId?: string;
  isPublic?: boolean;
  shopData?: shopData | null;
}) {
  useTrackingLog(
    isPublic ? "publicEnqueteOpen" : "enqueteOpen",
    { shopId },
    !!injectedShopData
  );
  const { data: systemSetting } = useClientDoc({ clientId: "default" });
  const { user, loading } = useAdminAuthentication();
  const { data: fetchedShopData } = useShopDoc({ shopId });
  const shopData = injectedShopData || fetchedShopData;

  const externalRedirectUrl = useMemo(() => {
    if (
      !injectedShopData &&
      shopData?.redirectURL &&
      checkTimeRange(shopData.redirectStartAt, shopData.redirectEndAt)
    ) {
      return shopData.redirectURL;
    }
  }, [shopData]);

  if (externalRedirectUrl) {
    (window as Window).location = externalRedirectUrl;
  }

  const { data: _surveyData } = useSurveyDoc({
    surveyId: shopData?.surveyId,
  });
  const { data: clientData } = useClientDoc({ clientId: shopData?.clientId });
  const { data: defaultClientData } = useClientDoc({ clientId: "default" });
  const { set } = useEnqueteDoc({ clientId: shopData?.clientId });
  const { formatMessage, getMessage } = useFormatter();
  const [locale] = useContextLocale();
  const s = useStringIntl();
  const navigate = useTypedNavigate();
  const contractSchema = getSchema({
    issuerId: shopData?.shopId || "",
    contractTitle: formatMessage("PrivacyPolicy.Title"),
    contractContent:
      s(clientData.privacyPolicy || defaultClientData.privacyPolicy) || "",
    email: user?.email,
    isPublic,
  });
  const surveyData = useMemoWithPrev<surveyData | undefined>(
    (prev) => (prev?.enquete ? prev : _surveyData),
    [_surveyData]
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const contractProperty = (contractSchema as unknown as any)?.properties[0];

  const documentBrowser = useDocumentBrowser();

  const parameter: SchemaFormParameter | undefined = surveyData?.enquete &&
    contractProperty && {
      title: formatMessage("Enquete.Title"),
      edit: {
        title: formatMessage("Form.Submit"),
        confirm: formatMessage("Form.Confirm"),
        handler: async (value) => {
          if (((user?.email && user?.uid) || isPublic) && shopData && shopId) {
            const data = {
              userId: user?.uid,
              email: user?.email,
              couponUrl: couponUrl(shopId, ":id"),
              shopId,
              data: value,
              surveyId: shopData.surveyId,
              userAgent: navigator.userAgent,
              locale,
              skipEventHook: v2Mode,
              cancelLottery: v2Mode,
            };
            Object.assign(data, getLogParams());

            const commentData = (() => {
              const commentFieldName = (() => {
                // tags に comment がついているフィールドがあればそれを使う、なければ multilineText を使う
                // 複数のフィールドに comment がついている場合は最後に見つかったフィールドを使う
                let _commentFieldName: string | undefined;
                let _multilineTextFieldName: string | undefined;
                for (const property of (surveyData?.enquete?.properties ||
                  []) as PropertyType[]) {
                  for (const tag of property.tags || []) {
                    if (tag === "comment") {
                      _commentFieldName = property.propertyName;
                    }
                  }
                  if (
                    property.schema.schemaType === "multilineText" ||
                    property.schema.schemaType === "multilineTextIntl"
                  ) {
                    _multilineTextFieldName = property.propertyName;
                  }
                }
                return _commentFieldName || _multilineTextFieldName; // commentタグありがあればそれを優先する
              })();
              return commentFieldName && value?.[commentFieldName];
            })();

            trackingLog(isPublic ? "publicEnqueteSubmit" : "enqueteSubmit", {
              shopId,
              surveyId: shopData.surveyId,
              locale,
            });

            const { enqueteId } = await set(data);

            if (isPublic) {
              navigate(
                "CONSUMER_ENQUETE_COMPLETED",
                { shopId },
                { state: { commentData } }
              );
            } else {
              navigate(
                "CONSUMER_GACHA",
                { shopId, enqueteId },
                { state: { commentData } }
              );
            }
          }
        },
      },
      enableFormAlert: true,
      schema: {
        ...surveyData?.enquete,
        properties: [
          contractProperty,
          ...surveyData?.enquete.properties,
          {
            propertyId: "termsOfService",
            schema: {
              schemaType: "message",
              text: () => (
                <InjectedHTML
                  value={getMessage("Enquete.TermsOfService") || ""}
                  params={{}}
                  elements={{
                    link: (
                      <a
                        href="javascript:void(0)"
                        onClick={(e) => {
                          e.preventDefault();
                          documentBrowser({
                            title: "利用規約",
                            message: termsOfServiceText,
                          });
                        }}
                      >
                        {getMessage("Enquete.TermsOfServieLink")}
                      </a>
                    ),
                  }}
                  containerTypes={{
                    link: "span",
                  }}
                />
              ),
            },
          },
        ],
      },
    };
  const isEnquatePublished = useMemo(() => {
    if (!shopData.surveyId || !surveyData?.enquete) {
      return false;
    }
    return checkTimeRange(shopData?.enqueteStartAt, shopData?.enqueteEndAt);
  }, [shopData, surveyData]);

  // shopDataの読み込み完了を判定する合理的な方法がないので、clientIdで判定している
  if (
    shopData.enableRedirection &&
    shopData?.clientId &&
    !shopData.isAuthSkipped &&
    shopId &&
    isPublic
  ) {
    return <Navigate to={resolveRoute("CONSUMER_ENTRY", { shopId })} />;
  }

  if (loading) {
    return <></>;
  }

  if (isMaintenanceMode(systemSetting)) {
    return <MaintenaceMessage systemSetting={systemSetting} />;
  }

  return (
    <div className={"wide-form"}>
      {(clientData?.availableLocales?.length || 0) > 1 ? (
        <div
          className="adjust-width"
          style={{ textAlign: "center", marginTop: "10px" }}
        >
          <ConsumerLocaleSelector
            locales={clientData?.availableLocales || []}
          />
        </div>
      ) : undefined}
      {parameter &&
        (isEnquatePublished ? (
          <SchemaFormPage data={{}} parameter={parameter} loading={loading} />
        ) : (
          <div
            className="adjust-width"
            style={{ textAlign: "center", marginTop: "10px" }}
          >
            <p style={{ fontSize: "24px", fontWeight: "bold" }}>
              {formatMessage("Enquete.NotAvailable")}
            </p>
          </div>
        ))}
    </div>
  );
}
export default {
  ConsumerEnquete,
  ConsumerPublicEnquete,
};
